import React from "react"
import LessonSeries from "../components/Lessons/LessonSeries";
import LessonsNav from '../components/Lessons/Nav'

const LessonSeriesPage = (props) => {
  if (typeof window === "undefined") return null

  return <>
    <LessonsNav {...props} />
    <LessonSeries />
  </>
}

export default LessonSeriesPage
