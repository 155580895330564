import { navigate } from 'gatsby'
import { TextArea, TextInput } from 'grommet'
import { Star } from 'grommet-icons'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'
import ImageUploader from '../../ImageUploaderOld'
import Button from '../../UI/Button'
import LessonSeriesState from './index.state'

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  > :first-child {
    width: 220px;
  }
`

const Series = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
`

const Serie = styled.div`
  position: relative;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  padding-top: 20px;
  cursor: pointer;
  > :first-child {
    height: 200px;
    width: 100%;
    background-image: url("${props => props.backgroundUrl || 'https://cdn.nightzookeeper.com/edu-assets/images/image-not-found.jpg'}");
    background-size: cover;
    background-position: center;
  }
`

const SerieInfo = styled.div`
  text-align: center;
  padding: 10px 0;
  border-radius: 0 0 8px 8px;
  > :first-child {
    font-weight: bold;
    font-size: 19px;
  }
`

const SerieCreationWrapper = styled.div`
  > div {
    > :first-child {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    margin-bottom: 15px;
  }
`

const Ages = styled.div`
  margin-left: auto;
  display: flex;
  margin-top: 5px;
  justify-content: center;
  > * {
    padding: 4px 3px;
    background-color: #fff;
    color: #000;
    margin-right: 4px;
    width: 22px;
    text-align: center;
  }
  > :first-child, > :last-child {
    width: auto;
    background-color: #ffffff00;
    color: #fff;
  }
  > :last-child {
    margin-right: 0;
  }
`

const AgeFilters = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
`

const AgeFilter = styled.div`
      padding: 4px 3px;
    background-color: ${colors.indigo};
    color: #fff;
    margin-right: 4px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    filter: ${props => props.selected ? `grayscale(0)` : `grayscale(1)`};
`

const Feature = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`

const LessonSeries = (props) => {
  const { series, createSerie, updateSerie } = LessonSeriesState.useContainer()
  const [creating, setCreating] = useState(false)
  const [newSerie, setNewSerie] = useState({ name: '', description: '' })
  const [searchTerm, setSearchTerm] = useState('')
  const ALL_AGES = [5,6,7,8,9,10,11,12]
  const [ageFilters, setAgeFilters] = useState([5,6,7,8,9,10,11,12])

  const create = () => {
    createSerie(newSerie)
    setNewSerie({ name: '', description: '' })
    setCreating(false)
  }

  const onFeatureClick = (e, serie) => {
    e.stopPropagation()
    e.preventDefault()
    updateSerie(serie._id, { featured: !serie.featured })
  }

  const displayedSeries = useMemo(() => {
    return series.filter(s => {
      let i = 0
      let found = false
      while (i < s.enabledYears.length) {
        let res = ageFilters.indexOf(s.enabledYears[i])
        if (res >= 0) { break }
        i++
      }
      if (i !== s.enabledYears.length) {
        found = true
      }
      return found && new RegExp(searchTerm.toLowerCase()).test(s.name.toLowerCase())
    })
  }, [series, searchTerm, ageFilters])

  const toggleAgeFilter = (age) => {
    setAgeFilters(ages => {
      if (ages.indexOf(age) >= 0) {
        return ages.filter(a => a !== age)
      }
      return [...ages, age].sort((a, b) => a - b)
    })
  }

  return <div>
    <Header>
      <TextInput onChange={e => setSearchTerm(e.target.value)} value={searchTerm} placeholder='Search for a lesson title' />
      <AgeFilters>
        { ALL_AGES.map(age => <AgeFilter key={age} selected={ageFilters.indexOf(age) >= 0} onClick={() => toggleAgeFilter(age)}>{age}</AgeFilter>) }
      </AgeFilters>
      <Button onClick={() => setCreating(d => !d)}>Create</Button>
    </Header>
    {
      creating && <SerieCreationWrapper>
        <div>
          <div>Title</div>
          <TextInput value={newSerie.name} onChange={e => setNewSerie({ ...newSerie, name: e.target.value })} />
        </div>
        <div>
          <div>Background</div>
          { !newSerie.backgroundUrl && <ImageUploader onComplete={(url) => setNewSerie({ ...newSerie, backgroundUrl: url })} /> }
          { newSerie.backgroundUrl && <img alt='Background' src={newSerie.backgroundUrl} /> }
        </div>
        <div>
          <div>Description</div>
          <TextArea value={newSerie.description} onChange={e => setNewSerie({ ...newSerie, description: e.target.description })} />
        </div>
        <Button onClick={() => create()}>Save</Button>
      </SerieCreationWrapper>
    }
    <Series>
      { displayedSeries.map((l, i) => <Serie key={l._id} {...l} onClick={() => navigate(`/lesson-serie/${l._id}`)}>
        <div />
        <SerieInfo>
          <div>
            {l.name}
          </div>
          <Ages>
            <div>Available for</div>{ (l.enabledYears || []).map(d => <div key={d}>{d}</div>) }<div>years old.</div>
          </Ages>
        </SerieInfo>
        <Feature onClick={(e) => onFeatureClick(e, l, i)}>
            <Star size='50px' color={l.featured ? '#ff0' : '#ebebeb'}/>
          </Feature>
      </Serie>) }
    </Series>
  </div>
}

LessonSeries.propTypes = {

}

LessonSeries.defaultProps = {

}

export default props => <LessonSeriesState.Provider>
  <LessonSeries {...props} />
</LessonSeriesState.Provider>
