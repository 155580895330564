import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'

const Wrapper = styled.div`
  display: flex;
  margin-top: -24px;
  margin-left: -24px;
  width: calc(100% + 48px);
  margin-bottom: 25px;
  > * {
    flex: 1;
    width: 100%;
    cursor: pointer;
  }
  > :last-child {
    > :first-child {
      border-right: none !important;
    }
  }
`

const Element = styled.div`
  background-color: ${colors.indigo};
  color: #fff;
  opacity: ${(props: { selected: boolean }) => props.selected ? 1 : 0.5};
  padding: 12px 12px;
  border-bottom: 2px solid ${colors.indigo};
  border-right: 1px solid #fff;
  font-size: 20px;
  font-weight: bold;
`

export default (props) => {
  return <Wrapper>
    <Link to='/lessons'>
      <Element selected={new RegExp('/lessons/').test(props.path)}>Lessons</Element>
    </Link>
    <Link to='/lesson-series'>
      <Element selected={new RegExp('/lesson-series/').test(props.path)}>Series</Element>
    </Link>
    <Link to='/lesson-tags'>
      <Element selected={new RegExp('/lesson-tags/').test(props.path)}>Tags</Element>
    </Link>
  </Wrapper>
}