import { createContainer } from "unstated-next"
import { useState, useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import GET_SERIES from './getLessonSeries.graphql'
import CREATE_SERIE from './createLessonSerie.graphql'
import UPDATE_SERIE from './updateLessonSerie.graphql'

const useLessonSeriesState = () => {
  const [series, setSeries] = useState([])
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  const fetch = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_SERIES,
      fetchPolicy: 'network-only'
    })
    setSeries(data.lessonV2Series.map(s => formatSerie(s)))
    setLoading(false)
  }

  const formatSerie = (serie) => {
    const AGES = [5,6,7,8,9,10,11,12]
    let enabledYears = [...AGES].filter(y => (serie.blockedForYearGroups || []).indexOf(y - 5) < 0)
    return { ...serie, enabledYears }
  }

  const createSerie = async (input) => {
    const { data } = await client.mutate({
      mutation: CREATE_SERIE,
      variables: {
        input
      }
    })
    setSeries([...series, formatSerie(data.createLessonV2Serie)])
  }
  
  const updateSerie = async (_id, input) => {
    const { data } = await client.mutate({
      mutation: UPDATE_SERIE,
      variables: {
        _id,
        input
      }
    })
    setSeries([...series].map(s => s._id === _id ? ({ ...formatSerie(data.updateLessonV2Serie) }) : formatSerie(s)))
  }

  return { series, loading, updateSerie, createSerie }
}

export default createContainer(useLessonSeriesState)
