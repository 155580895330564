import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../UI/Button'
import Axios from 'axios'
import config from '../../config'
import { TextInput } from 'grommet'

const Wrapper = styled.div`
  height: 200px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed #555;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > div {
    width: 100%;
    padding: 0 30px;
    > :first-child {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > :first-child {
    margin-bottom: 30px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const ErrorMessage = styled.div`
  color: red;
`

const ImageUploader = (props) => {
  const [file, setFile] = useState(null)
  const textInputRef = useRef()
  const [error, setError] = useState(null)

  const upload = async () => {
    let bodyFormData = new FormData();
    bodyFormData.set('path', props.basePath)
    bodyFormData.append('file', file)
    const { data } = await Axios({
      method: "POST",
      url: config.fileServer,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    // Returns url
    props.onComplete(data.data)
  }

  const onSubmit = async (url) => {
    setError(null)
    if (!new RegExp(/^https/).test(url)) {
      setError('URL has to be https')
      return
    }
    try {
      const res = await Axios.head(url)
      if (res.status === 200) {
        props.onComplete(url)
      }
    } catch (err) {
      setError(err.message)
    }
  }

  return <Wrapper>
    <div>
      <div>Upload a file</div>
      <input type="file" onChange={e => setFile(e.target.files[0])} />
      { file && <Button onClick={upload}>Upload</Button> }
    </div>
    <div>
      <div>Or use a link</div>
      { error && <ErrorMessage>{error}</ErrorMessage> }
      <TextInput type='text' placeholder='File URL' ref={textInputRef} onKeyPress={e => e.which === 13 && onSubmit(textInputRef.current.value)} />
    </div>
  </Wrapper>
}

ImageUploader.propTypes = {
  checkUrl: PropTypes.func
}

ImageUploader.defaultProps = {
  basePath: 'lessons/',
  onComplete: (url) => {},
  checkUrl: () => {}
}

export default ImageUploader
